.account-head .mstd-nav {
    max-width: none !important;
}

.account-head .tab-box {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
}

.account-head .tab-box li {
    vertical-align: top;
    text-align: center;
}

.account-head .tab-box li span {
    display: block;
}
.uib-typeahead-match a i {
    color: #929090;
}
.uib-typeahead-match.active a i {
    color: #fff;
}